import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton, PhoneInput } from '../../../components';

import css from './SignupForm.module.css';

import { BUSINESS, INDIVIDUAL } from '../../../constants';

const SignupFormComponent = props => {
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  return (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        values,
        form,
        config,
        touched,
      } = fieldRenderProps;

      const isSomeFieldTouched = Object.values(touched).includes(true);

      const isBusiness = values.accountType === BUSINESS;
      const showErrorUserType = isSomeFieldTouched && !values.accountType;
      const showErrorUserBusinessType = isSomeFieldTouched && isBusiness && !values.accountBusinessType;

      const phonePlaceholder = intl.formatMessage({
        id: 'SignupForm.phonePlaceholder',
      });
      const phoneLabel = intl.formatMessage({ id: 'SignupForm.phoneLabel' });

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled =
        invalid ||
        submitInProgress ||
        !values.accountType ||
        showErrorUserBusinessType ||
        !values.phoneNumber ||
        phoneError;

      const setValue = (field, value) => form.change(field, value);

      const isValidNumber = number => {
        if (!phoneTouched) {
          setPhoneTouched(true);
        }

        let valid = true;

        if (number[0] === "+") {
          valid = number.length -1 >= 6;
        } else {
          valid = number.length >= 6;
        }

        if (valid && phoneError) {
          setPhoneError(false);
        } else if (!valid && !phoneError) {
          setPhoneError(true);
        }

        return valid;
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }} onChange={({ values }) => {
            if (values.accountType === INDIVIDUAL && values.accountBusinessType) {
              setValue("accountBusinessType", undefined);
            }
          }} />
          <h2 className={css.headlines}>{intl.formatMessage({ id: 'SignupForm.userTypeTitle' })}</h2>
          <div className={css.radioFields}>
            <FieldRadioButton
              id="individual"
              name="accountType"
              label={intl.formatMessage({ id: 'SignupForm.individual' })}
              value="individual"
            />
            <FieldRadioButton
              id="business"
              name="accountType"
              label={intl.formatMessage({
                id: 'SignupForm.business',
              })}
              value="business"
            />
          </div>
          {showErrorUserType ? (
            <span className={css.error}>{intl.formatMessage({ id: 'SignupForm.userTypeRequired' })}</span>
          ) : null}
          {isBusiness ? (
            <>
              <h2 className={css.headlines}>{intl.formatMessage({ id: 'SignupForm.userBusinessTypeTitle' })}</h2>
              <div className={css.radioFields}>
                <FieldRadioButton
                  id="equipmentProvider"
                  name="accountBusinessType"
                  label={intl.formatMessage({ id: 'SignupForm.equipmentProvider' })}
                  value="equipmentProvider"
                />
                <FieldRadioButton
                  id="equipmentProcurement"
                  name="accountBusinessType"
                  label={intl.formatMessage({ id: 'SignupForm.equipmentProcurement' })}
                  value="equipmentProcurement"
                />
              </div>
              {showErrorUserBusinessType ? (
                <span className={css.error}>{intl.formatMessage({ id: 'SignupForm.userBusinessTypeRequired' })}</span>
              ) : null}
            </>
          ) : null}
          <div>
            <FieldTextInput
              className={css.emailField}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <PhoneInput
              name="phoneNumber"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              label={phoneLabel}
              placeholder={phonePlaceholder}
              value={values.phoneNumber}
              onChange={(value) => setValue("phoneNumber", value)}
              intl={intl}
              validate={isValidNumber}
              config={config}
              phoneFieldTouched={phoneTouched}
              onBlur={() => !phoneTouched && setPhoneTouched(true)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
)
}

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
